import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
// import PostTags from "../components/PostTags/PostTags";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import "./b16-tomorrow-dark.css";
import "./post.css";

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <div>
            <div className="page">
              <section className="page-image-section">
                <div className="page-image-wrapper">
                  <img
                    className="page-image"
                    alt={post.title}
                    src={post.cover}
                  />
                  <div className='blog-header'>
                    <h1 className="page-title">{post.title}</h1>
                    <div className="blogContentDetail">
                      <time>{post.date}</time>
                    </div>
                  </div>
                </div>
              </section>
              {/* <div className="post-meta">
                <PostTags tags={post.tags} />
              </div> */}

              <article
                dangerouslySetInnerHTML={{ __html: postNode.html }}
                className="page-article"
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date(locale: "TR", formatString: "LL")
        category
        tags
      }
      fields {
        slug
        date(locale: "TR", formatString: "LL")
      }
    }
  }
`;
